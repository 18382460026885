<template>
  <div class="ErrorContent_content">
    <div class="Container_default">
      <div class="ErrorContent_status">
        404
      </div>
      <div>
        <h1 class="ErrorContent_message">
          このページはすでに削除されているか、URLが間違っている可能性があります。
        </h1>
      </div>
      <v-btn color="primary" class="Button_default ma-3 pa-6" to="/">
        トップへ戻る
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  mdiAlert,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
#app {
  background: url("./images/221122_bimistock_image_05_1920.jpg") no-repeat center center fixed;
  background-size: cover;
}
.ErrorContent_content{
  min-height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ErrorContent_status{
  font-size: 10rem;
  font-weight: 700;
  color: rgb(255, 249, 242);
  margin-bottom: 2rem;
}
.Container_default{
  padding: 0 1rem;
  display: block;
  background-color: rgba(78, 78, 78, 0.251);
}
.ErrorContent_message{
  display: inline-block;
  color:rgb(255, 249, 242);
}
.Button_default{
  font-size: 1.5rem !important;
  padding: .6em 1.5em;
  font-weight: 700;
  height: 50%;
}
</style>
